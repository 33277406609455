<template>
	<b-row>
		<b-col cols="12">
			<b-card>
				<b-tabs>
					<b-tab active>
						<validation-observer ref="observer" v-slot="{ handleSubmit }">
							<b-form class="p-0"
								@submit.prevent="handleSubmit(guardar)"
							>
								<b-row>
									<b-col cols="12">
										<b-form-group label="Empleado" label-size="sm">
											<b-form-input trim size="sm" disabled :value="empleado"/>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row>
									<b-col cols="12" md="6">
										<validation-provider #default="validationContext"
											name="Fecha Antiguedad Anterior"
											rules="required"
										>
											<b-form-group label="Fecha de Antiguedad Anterior" label-for="txtFechaAntiguedadAnterior">
												<b-form-datepicker
													disabled
													v-model="fechaAntiguedadAnterior"
													:state="getValidationState(validationContext)"
													placeholder="Seleccione una fecha" dark
													:date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
													reset-button label-reset-button="Limpiar"
													label-prev-month="mes anterior" label-next-month="mes siguiente"
													label-prev-year="a�o anterior" label-next-year="a�o siguiente"
													label-current-month="mes actual"
													label-no-date-selected="seleccione una fecha" label-help="">
												</b-form-datepicker>
												<b-form-invalid-feedback> {{ validationContext.errors[0] }} </b-form-invalid-feedback>
											</b-form-group>
										</validation-provider>
									</b-col>

									<b-col cols="12" md="6">
										<validation-provider #default="validationContext"
											name="Fecha Antiguedad Nueva"
											rules="required"
										>
											<b-form-group label="Fecha de Antiguedad Nueva" label-for="txtFechaAntiguedadNueva">
												<b-form-datepicker
													v-model="fechaAntiguedadNueva"
													:state="getValidationState(validationContext)"
													placeholder="Seleccione una fecha" dark
													:date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
													reset-button label-reset-button="Limpiar"
													label-prev-month="mes anterior" label-next-month="mes siguiente"
													label-prev-year="a�o anterior" label-next-year="a�o siguiente"
													label-current-month="mes actual"
													label-no-date-selected="seleccione una fecha" label-help="">
												</b-form-datepicker>
												<b-form-invalid-feedback> {{ validationContext.errors[0] }} </b-form-invalid-feedback>
											</b-form-group>
										</validation-provider>
									</b-col>	

								</b-row>

								<!-- Botones -->
								<div class="d-flex float-right mt-2">
									<b-button class="mr-2" variant="primary" type="guardar">
										Guardar
									</b-button>
									<b-button variant="secondary" type="button" @click="cancelar">
										Cancelar
									</b-button>
								</div>

							</b-form>
						</validation-observer>
					</b-tab>
				</b-tabs>
			</b-card>
		</b-col>
	</b-row>
</template>

<script>
	import {
		BTab, BTabs, BCard, BAlert, BLink, BForm, BFormGroup, BFormInput, BFormDatepicker,
		BFormTextarea, BFormInvalidFeedback, BButton, BFormSelect, BRow, BCol, BFormCheckbox
	} from 'bootstrap-vue'
   import axios from "@axios"
	import router from "@/router";
	import { ValidationObserver, ValidationProvider, } from "vee-validate";
	import { required, alphaNum, email, numeric } from "@validations";
	import vSelect from "vue-select";
   import utilerias from '@/views/utilerias'
	import CustomBFormSelect from '@core/components/CustomBFormSelect.vue'
	import CustomVSelect from '@core/components/CustomVSelect.vue'

	const {
		msgToast,
		msjConfirmarGuardar,
		msjConfirmarCancelarGuardar,
	} = utilerias()

	export default {
		components: {
			BTab, BTabs, BCard, BAlert, BLink, BForm, BFormGroup, BFormInput, BFormDatepicker,
			BFormTextarea, BFormInvalidFeedback, BButton, BFormSelect, BRow, BCol, BFormCheckbox,
			vSelect,
			ValidationProvider,
			ValidationObserver,
			CustomBFormSelect, CustomVSelect,
		},
		data() {
			return {
				matricula: '',
				empleado: '',
				fechaAntiguedadAnterior: '',
				fechaAntiguedadNueva: '',
    		};
  		},
		async mounted() {
			await this.cargaDatos()
		},
  		methods: {
   		getValidationState({ dirty, validated, valid = null }) {
				return dirty || validated ? valid : null;
			},
			async cargaDatos() {
				const r = await axios.get(`/api/mov-ajuste-fecha-antiguedad/${router.currentRoute.params.id}`, { withCredentials: true })
				this.matricula = r.data[0].Matricula_mov;
				this.empleado = r.data[0].Matricula_mov + ' - ' + r.data[0].Nombre;				
				this.fechaAntiguedadAnterior = r.data[0].FechaAntiguedadAnterior;
				this.fechaAntiguedadNueva = r.data[0].FechaAntiguedadNueva;
			},
    		cancelar() {
				msjConfirmarCancelarGuardar.fire({}).then((result) => {
					if (result.isConfirmed) {
						this.$refs.observer.reset();
						router.go(-1);
					}
				});
    		},
    		guardar() {
				msjConfirmarGuardar.fire({}).then((result) => {
					if (result.isConfirmed) {
						const payload = {
							Matricula: this.matricula,
							FechaAntiguedadAnterior: this.fechaAntiguedadAnterior,
							FechaAntiguedadNueva: this.fechaAntiguedadNueva,
						};
						axios
						.put(`/api/mov-ajuste-fecha-antiguedad/${router.currentRoute.params.id}`, payload, { withCredentials: true })
						.then(response => {
							msgToast.fire({ icon: "success", title: "Movimiento registrado", });
							router.go(-1);
						});
					}
				});
    		}
  		}
	};
</script>

<style>
	.content-header .breadcrumb {
		display: flex !important;
	}
</style>
