<template>
	<div>
		<b-form-group :label="titulo">
			<v-select label="Nombre" @input="handleChange"
				:reduce="(option) => option.Codigo"
				:options="data"
				v-model="value"
				:disabled="disabled"
			>
				<template #search="{attributes, events}">
					<input
						class="vs__search"
						:required="!value"
						v-bind="attributes"
						v-on="events"
					/>
				</template>
				<template v-slot:no-options="{ search, searching }">
					<template v-if="searching">
						No se encontraron resultados para <em>{{ search }}</em>.
					</template>
					<em style="opacity: 0.5;" v-else>Comienza a escribir para buscar</em>
				</template>
			</v-select>
		</b-form-group>
	</div>
</template>

<script>
import { BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@axios"

export default {
	props: {
		titulo: {
			type: String,
			default: 'Titulo'
	 	},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	components: {
		BFormGroup, vSelect,
	},
	data() {
		return {
			value: '',
			data: [],
		}
	},
	mounted() {
		this.$emit('load', this.loadMethod)
	},
	methods: {
		handleChange (value) {
			this.$emit("change", value)
		},
		async loadMethod(catalogo, selecteItem) {
			let r = await axios.get(catalogo);
			this.data = r.data.data;
			this.value = selecteItem;
		}
	},
}
</script>