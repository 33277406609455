<template>
	<div>
		<validation-provider v-slot="validationContext"
			:name="titulo"
			rules="required"
		>
			<b-form-group :label="titulo">
				<b-form-select
					@change="handleChange"
					:options="data" value-field="Codigo" text-field="Nombre"
					v-model="value"
					:state="getValidationState(validationContext)"
				/>
				<b-form-invalid-feedback> {{ validationContext.errors[0] }} </b-form-invalid-feedback>
			</b-form-group>
		</validation-provider>
	</div>
</template>

<script>
import axios from "@axios"
import { BFormGroup, BFormSelect, BFormInvalidFeedback} from "bootstrap-vue";
import { ValidationProvider, } from "vee-validate";
import { required } from "@validations";

export default {
	props: {
		titulo: {
			type: String,
			default: 'Titulo'
	 	},
	},
	components: {
		BFormGroup, BFormSelect, BFormInvalidFeedback, ValidationProvider
	},
	data() {
		return {
			value: '',
			data: [],
		}
	},
	mounted() {
		this.$emit('load', this.loadMethod)
	},
	methods: {
  		getValidationState({ dirty, validated, valid = null }) {
     		return dirty || validated ? valid : null;
  		},
		handleChange (value) {
			this.$emit("change", value)
		},
		async loadMethod(catalogo, selecteItem) {
			let r = await axios.get(catalogo);
			this.data = r.data.data;
			this.value = selecteItem;
		}
	},
}
</script>